import certificateImage1 from "../images/certyfikaty/skompresowane/skompresowane3/2003-09-23-min.png";
import certificateImage2 from "../images/certyfikaty/skompresowane/skompresowane3/2001-05-18-min.png";
import certificateImage3 from "../images/certyfikaty/skompresowane/skompresowane3/2019-11-24-min.png";
import certificateImage4 from "../images/certyfikaty/skompresowane/skompresowane3/2001-04-min.png";
import certificateImage5 from "../images/certyfikaty/skompresowane/skompresowane3/1997-08-21-min.png";
import certificateImage6 from "../images/certyfikaty/skompresowane/skompresowane3/2003-04-26-min.png";
import certificateImage7 from "../images/certyfikaty/skompresowane/skompresowane3/2003-05-31-min.png";
import certificateImage8 from "../images/certyfikaty/skompresowane/skompresowane3/2003-06-28-min.png";
import certificateImage9 from "../images/certyfikaty/skompresowane/skompresowane3/1997-02-20-min.png";
import certificateImage10 from "../images/certyfikaty/skompresowane/skompresowane3/2005-01-12-min.png";
import certificateImage11 from "../images/certyfikaty/skompresowane/skompresowane3/2006-06-27-min.png";
import certificateImage12 from "../images/certyfikaty/skompresowane/skompresowane3/2007-03-16-min.png";
import certificateImage13 from "../images/certyfikaty/skompresowane/skompresowane3/2007-09-25-min.png";
import certificateImage14 from "../images/certyfikaty/skompresowane/skompresowane3/2008-03-14-min.png";
import certificateImage15 from "../images/certyfikaty/skompresowane/skompresowane3/2009-12-01-min.png";
import certificateImage16 from "../images/certyfikaty/skompresowane/skompresowane3/2017-02-27-min.png";
import certificateImage17 from "../images/certyfikaty/skompresowane/skompresowane3/1997-08-25-min.png";
import certificateImage18 from "../images/certyfikaty/skompresowane/skompresowane3/1997-08-25a-min.png";
import certificateImage19 from "../images/certyfikaty/skompresowane/skompresowane3/2003-07-23b-min.png";
import certificateImage20 from "../images/certyfikaty/skompresowane/skompresowane3/2017-06-03-min.png";
const certs = {
  certificateImage1,
  certificateImage2,
  certificateImage3,
  certificateImage4,
  certificateImage5,
  certificateImage6,
  certificateImage7,
  certificateImage8,
  certificateImage9,
  certificateImage10,
  certificateImage11,
  certificateImage12,
  certificateImage13,
  certificateImage14,
  certificateImage15,
  certificateImage16,
  certificateImage17,
  certificateImage18,
  certificateImage19,
  certificateImage20,
};
export default certs;
